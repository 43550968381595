import React from "react";
import { withTranslation } from "react-i18next";
import mimaroba from "../../assets/OtherProjects/mimaroba.jpg";
import ulus from "../../assets/OtherProjects/ulus.jpg";
import thumbnailMimaroba from "../../assets/OtherProjects/thumbnail/mimaroba.jpg";
import thumbnailUlus from "../../assets/OtherProjects/thumbnail/ulus.jpg";
import LazyLoad from "react-lazy-load";

const OtherProjectsContinuedLayout = ({ t }) => {
    return (
        <section className="uk-section uk-padding-remove-bottom">
            <div className="uk-container uk-container-large">
                <div className="uk-child-width-1-2 uk-child-width-1-4@m" data-uk-grid data-uk-lightbox data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div>
                        <div className="uk-text-center">
                            <a href={mimaroba} className="uk-link-reset" data-caption="Mimaroba">
                                <div className="uk-inline-clip uk-transition-toggle uk-width-1-1" tabIndex="0">
                                    <LazyLoad>
                                        <img src={thumbnailMimaroba} alt="" loading="lazy" className="uk-border-rounded uk-width-1-1" height={340} width={320}/>
                                    </LazyLoad>
                                    <div className="uk-position-center">
                                        <div className="uk-transition-slide-top-small"><div className="uk-h3 uk-margin-remove uk-text-bold text-white">Mimaroba</div></div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className="uk-text-center">
                            <a href={ulus} className="uk-link-reset" data-caption="Ulus Residence">
                                <div className="uk-inline-clip uk-transition-toggle uk-width-1-1" tabIndex="0">
                                    <LazyLoad>
                                        <img src={thumbnailUlus} alt="" loading="lazy" className="uk-border-rounded uk-width-1-1" height={340} width={320}/>
                                    </LazyLoad>
                                    <div className="uk-position-center">
                                        <div className="uk-transition-slide-top-small"><div className="uk-h3 uk-margin-remove uk-text-bold text-white">Ulus</div></div>
                                        <div className="uk-transition-slide-bottom-small"><div className="uk-h3 uk-margin-remove text-white uk-text-bold">Residence</div></div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(OtherProjectsContinuedLayout);