/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import projectDetailArray from "../../data/projectDetailData";
import LazyLoad from "react-lazy-load";

const ProjectDetailCards = ({ t }) => {
    return (
        <>
            <section className="uk-section uk-padding-remove-bottom project-detail-cards-section">
                <div className="uk-grid-collapse uk-child-width-1-2@m uk-flex-center" data-uk-grid>
                    {projectDetailArray.map((item) => (
                        <div className="card" key={item._id}>
                            <div className="uk-grid-collapse uk-child-width-1-2@m uk-grid-match" data-uk-scrollspy="cls:uk-animation-fade; delay:200; target: * > div"  data-uk-grid>
                                <div>
                                    <div className="image-box">
                                        <LazyLoad className="uk-width-1-1 uk-height-1-1">
                                            <img src={process.env.PUBLIC_URL + `/assets/ProjectDetail/${item.image}`} alt={item.alt} loading="lazy" className="uk-width-1-1 uk-height-1-1 uk-object-cover" height={500} width={370}/>
                                        </LazyLoad>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-padding uk-height-1-1">
                                        <div className="number-title emphasis-color">{item.id}</div>
                                        <div className="title uk-text-secondary uk-text-500 uk-margin-top">{item.title}</div>
                                        <p>{item.desc}</p>
                                        <div className="uk-text-right link">
                                            <Link to={item.url} className="uk-link-reset">Detay</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </>
    );
};

export default withTranslation()(ProjectDetailCards);