import React from "react";
import { withTranslation } from "react-i18next";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";
import image from "../../assets/Home/home-1.jpeg";

const HomeFirstSplitColumn = ({ t }) => {
    return (
        <section className="uk-section uk-padding-remove-bottom">
            <div className="uk-container uk-container-xlarge">
                <div className="uk-child-width-1-2@m uk-flex-middle uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div>
                        <div className="background-position uk-width-1-1 home-1 uk-visible@m"></div>
                        <LazyLoad>
                            <img src={image} alt="home-1" loading="lazy" className="uk-width-1-1 uk-hidden@m" height={600} width={700}/>
                        </LazyLoad>
                    </div>
                    <div className="uk-padding">
                        <div className="uk-h3 uk-text-bold uk-margin-remove">Şehrin içinde, şehir gürültüsünden uzak</div>
                        <div className="uk-h4">
                            Eğitim ve ticaret bölgelerinin ortasında kurulan Botanika Villaları sakin bir atmosfere sahip olacak şekilde tasarlanmıştır. Şehir merkezine 10 dakika uzaklıkta bulunan proje, şehir hayatının hem içinde hem de dışında kalabilmektedir.
                        </div>
                        <Link to="/project-detail" className="uk-button uk-button-primary">Detay</Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(HomeFirstSplitColumn);