/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { withTranslation } from "react-i18next";

const GalleryIntroduction = ({ t }) => {
    return (
        <section className="uk-section">
            <div className="uk-container">
                <div className="uk-flex uk-flex-center uk-text-center" data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div>
                        <h1 className="uk-h2 uk-text-bold">Galeri</h1>
                        <div>Sizi villamızın bütün resimlerini görmeye davet ediyoruz.</div>
                        <ul className="uk-flex-center uk-subnav uk-subnav-pill uk-margin-medium-top uk-margin-remove-bottom">
                            <li className="uk-active" uk-filter-control="[data-tag='interiors']"><a href="#">İç Mekanlar</a></li>
                            <li uk-filter-control="[data-tag='outdoors']"><a href="#">Dış Mekanlar</a></li>
                            <li uk-filter-control="[data-tag='projects-plan']"><a href="#">Proje Planları</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(GalleryIntroduction);