/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";

const Content = ({ t }) => {
    return (
        <div className="uk-padding">
            <div className="uk-h1 uk-text-bold uk-margin-bottom">Sosyal Aktivite <span className="emphasis-color">Alanları</span></div>
            <p className="uk-text-muted">Hayallerinize göre kendi tasarımını yapabileceğiniz aktivite alanları sunuyoruz. Sinema odası, fıtness, sauna, hamam…  Hayal etmek sizden inşa etmek bizden …</p>
        </div>
    );
};

export default withTranslation()(Content);