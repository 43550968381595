/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";

const ProjectPlansSiteFeatures = ({ t }) => {
    return (
        <>
            <section className="uk-section">
                <div className="uk-container">
                    <div className="uk-flex uk-flex-center uk-flex-middle uk-text-center" data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                        <div>
                            <h1 className="uk-text-bold uk-margin-remove">Proje <span className="emphasis-color">Planları</span></h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withTranslation()(ProjectPlansSiteFeatures);