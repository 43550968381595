import React from "react";
import { withTranslation } from "react-i18next";
import Highlight from "../components/UnderfloorHeatingSystem/Highlight";

const UnderfloorHeatingSystemScreen = ({ t }) => {
    return (
        <>
            <Highlight/>
        </>
    );
};

export default withTranslation()(UnderfloorHeatingSystemScreen);