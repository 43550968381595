/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";

const ProjectPlansIntro = ({ t }) => {
    return (
        <>
            <section className="uk-section uk-padding-remove-bottom">
                <div className="uk-container uk-container-large">
                    <div className="uk-h1 uk-text-bold" data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                        <span className="emphasis-color">Zemin </span><span>Kat</span>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withTranslation()(ProjectPlansIntro);