const translations = {
  en: {
    translation: {
      test: "Test",
    },
  },
  tr: {
    translation: {
      test: "Deneme",
    },
  },
};

export default translations;