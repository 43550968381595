/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import LazyLoad from "react-lazy-load";
import image1 from "../../assets/ProjectDetail/bodrum.png";

const ProjectPlansLayoutThree = ({ t }) => {
    return (
        <>
            <section className="uk-section">
                <div className="uk-container uk-container-large">
                    <div className="uk-width-1-1" data-uk-lightbox data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                            <a className="uk-inline-clip uk-transition-toggle" tabIndex="0" href={image1}>
                                <LazyLoad>
                                    <img src={image1} alt="" data-uk-img loading="lazy" height={620} width={440}/>
                                </LazyLoad>
                                <div className="uk-transition-fade uk-position-cover uk-overlay uk-overlay-default uk-flex uk-flex-center uk-flex-middle">
                                    <div className="emphasis-color" data-uk-icon="icon:expand; ratio:3;"/>
                                </div>
                            </a>
                        </div>
                </div>
            </section>
        </>
    );
};

export default withTranslation()(ProjectPlansLayoutThree);