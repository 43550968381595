import React from "react";
import { withTranslation } from "react-i18next";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";
import image from "../../assets/Home/home-2.jpeg";

const HomeSecondSplitColumn = ({ t }) => {
    return (
        <section>
            <div className="uk-container uk-container-xlarge">
                <div className="uk-child-width-1-2@m uk-flex-middle uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="uk-flex-last uk-flex-first@m">
                        <div className="uk-padding">
                            <div className="uk-h3 uk-text-bold uk-margin-remove">İhtiyacınız olan her şey elinizin altında.</div>
                            <div className="uk-h4">
                                Botanika Villaları, Okan Hastanesi ve Üniversitesi'ne komşu, Formula 1 pistine ve Viaport Alışveriş Merkezine 5 dakika olup Sabiha Gökçen Havaalanı'na ,Koç Lisesi, Şişli Terakki, Bilfen, İstanbul Medeniyet Üniversitesi ve Sabancı Üniversitesi'ne yakın konumdadır.
                            </div>
                            <Link to="/other-projects" className="uk-button uk-button-primary">Detay</Link>
                        </div>
                    </div>
                    <div className="uk-flex-first uk-flex-last@m">
                        <div className="background-position uk-width-1-1 home-2 uk-visible@m"></div>
                        <LazyLoad>
                            <img src={image} alt="home-2" loading="lazy" className="uk-width-1-1 uk-hidden@m"  height={600} width={700}/>
                        </LazyLoad>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(HomeSecondSplitColumn);