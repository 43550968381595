import React from "react";
import { withTranslation } from "react-i18next";
import ContactSection from "../components/Common/ContactSection";
import HomeBanner from "../components/Home/HomeBanner";
import HomeFirstSplitColumn from "../components/Home/HomeFirstSplitColumn";
import HomeSecondSplitColumn from "../components/Home/HomeSecondSplitColumn";
import HomeThirdSplitColumn from "../components/Home/HomeThirdSplitColumn";

const HomeScreen = ({ t }) => {
    return (
        <div>
            <HomeBanner/>
            <HomeFirstSplitColumn/>
            <HomeSecondSplitColumn/>
            <HomeThirdSplitColumn/>
            <ContactSection/>
        </div>
    );
};

export default withTranslation()(HomeScreen);