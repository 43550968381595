import { Routes,Route, useLocation} from 'react-router-dom';
import Navbar from './components/Navbar';
import HomeScreen from './pages/HomeScreen';
import { useEffect, useState } from 'react';
import Sidebar from './components/Sidebar/Sidebar';
import Footer from './components/Footer';
import GalleryScreen from './pages/GalleryScreen';
import ProjectDetailScreen from './pages/ProjectDetailScreen'
import ContactScreen from './pages/ContactScreen';
import OtherProjectsScreen from './pages/OtherProjectsScreen';
import ProjectPlansScreen from './pages/ProjectPlansScreen';
import SmartHomeSystemScreen from './pages/SmartHomeSystemScreen';
import UnderfloorHeatingSystemScreen from './pages/UnderfloorHeatingSystemScreen';
import PrivateSwimmingPoolScreen from './pages/PrivateSwimmingPoolScreen';
import ParkingGarageScreen from './pages/ParkingGarageScreen';
import SocialActivityAreasScreen from './pages/SocialActivityAreasScreen';

function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  const [isopen, setisopen] = useState(false)
  const toggle = () => {
    setisopen(!isopen)
  }
  return (
    <>
      <Navbar toggle={toggle}/>
      <Sidebar isopen={isopen} toggle={toggle} />
      <ScrollToTop/>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/gallery" element={<GalleryScreen />} />
          <Route path="/project-detail" element={<ProjectDetailScreen />} />
          <Route path="/project-plans" element={<ProjectPlansScreen />} />
          <Route path="/other-projects" element={<OtherProjectsScreen />} />
          <Route path="/contact" element={<ContactScreen />} />
          <Route path="/smart-home-system" element={<SmartHomeSystemScreen />} />
          <Route path="/underfloor-heating-system" element={<UnderfloorHeatingSystemScreen />} />
          <Route path="/private-swimming-pool" element={<PrivateSwimmingPoolScreen />} />
          <Route path="/parking-garage" element={<ParkingGarageScreen />} />
          <Route path="/social-activity-areas" element={<SocialActivityAreasScreen />} />
          <Route path="*" element={<HomeScreen />} />
        </Routes>
      <Footer/>
    </>
  );
}

export default App;