import React from "react";
import { withTranslation } from "react-i18next";
import LazyLoad from "react-lazy-load";
import interiorsImagesArray from "../../data/interiorsImagesData";
import outDoorsImagesArray from "../../data/outDoorsImagesData";
import projectsPlanImagesArray from "../../data/projectsPlanImagesData";

const GalleryLayout = ({ t }) => {
    return (
        <section className="uk-section uk-padding-remove-top gallery-section">
            <div className="uk-container uk-container-xlarge">
                <ul className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-small js-filter" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;" data-uk-lightbox>
                    {interiorsImagesArray.map((item) => (
                        <li data-tag={item.tag} key={item._id}>
                            <a href={process.env.PUBLIC_URL + `/assets/Gallery/${item.tag}/${item.name}.${item.fileExtension}`} className="uk-width-1-1 uk-height-1-1">
                                <div className="image-box">
                                    <LazyLoad className="uk-width-1-1 uk-height-1-1">
                                        <img src={process.env.PUBLIC_URL + `/assets/Gallery/${item.tag}/${item.name}.${item.fileExtension}`} alt={item.name} loading="lazy" data-uk-img className="uk-width-1-1 uk-height-1-1 uk-object-cover" height={255} width={465}/>
                                    </LazyLoad>
                                </div>
                            </a>
                        </li>
                    ))}
                    {outDoorsImagesArray.map((item) => (
                        <li data-tag={item.tag} key={item._id}>
                            <a href={process.env.PUBLIC_URL + `/assets/Gallery/${item.tag}/${item.name}.${item.fileExtension}`} className="uk-width-1-1 uk-height-1-1">
                                <div className="image-box">
                                    <LazyLoad className="uk-width-1-1 uk-height-1-1">
                                        <img src={process.env.PUBLIC_URL + `/assets/Gallery/${item.tag}/${item.name}.${item.fileExtension}`} alt={item.name} loading="lazy" data-uk-img className="uk-width-1-1 uk-height-1-1 uk-object-cover" height={255} width={465}/>
                                    </LazyLoad>
                                </div>
                            </a>
                        </li>
                    ))}
                    {projectsPlanImagesArray.map((item) => (
                        <li data-tag={item.tag} key={item._id}>
                            <a href={process.env.PUBLIC_URL + `/assets/Gallery/${item.tag}/${item.name}.${item.fileExtension}`} className="uk-width-1-1 uk-height-1-1">
                                <div className="image-box">
                                    <LazyLoad className="uk-width-1-1 uk-height-1-1">
                                        <img src={process.env.PUBLIC_URL + `/assets/Gallery/${item.tag}/${item.name}.${item.fileExtension}`} alt={item.name} loading="lazy" data-uk-img className="uk-width-1-1 uk-height-1-1 uk-object-cover" height={255} width={465}/>
                                    </LazyLoad>
                                </div>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default withTranslation()(GalleryLayout);