const interiorsImagesArray = [
    {
        tag:'interiors',
        name:'interiors-1',
        fileExtension:'jpg',
    },
    {
        tag:'interiors',
        name:'interiors-2',
        fileExtension:'jpg',
    },
    {
        tag:'interiors',
        name:'interiors-3',
        fileExtension:'jpg',
    },
    {
        tag:'interiors',
        name:'interiors-4',
        fileExtension:'jpg',
    },
    {
        tag:'interiors',
        name:'interiors-5',
        fileExtension:'jpg',
    },
    {
        tag:'interiors',
        name:'interiors-6',
        fileExtension:'jpg',
    },
    {
        tag:'interiors',
        name:'interiors-7',
        fileExtension:'jpg',
    },
    {
        tag:'interiors',
        name:'interiors-8',
        fileExtension:'jpg',
    },
    {
        tag:'interiors',
        name:'interiors-9',
        fileExtension:'jpg',
    },
    {
        tag:'interiors',
        name:'interiors-10',
        fileExtension:'jpg',
    },
    {
        tag:'interiors',
        name:'interiors-11',
        fileExtension:'jpeg',
    },
    {
        tag:'interiors',
        name:'interiors-12',
        fileExtension:'jpeg',
    },
    {
        tag:'interiors',
        name:'interiors-13',
        fileExtension:'jpeg',
    },
    {
        tag:'interiors',
        name:'interiors-14',
        fileExtension:'jpeg',
    },
    {
        tag:'interiors',
        name:'interiors-15',
        fileExtension:'jpeg',
    },
    {
        tag:'interiors',
        name:'interiors-16',
        fileExtension:'jpeg',
    },
    {
        tag:'interiors',
        name:'interiors-17',
        fileExtension:'jpeg',
    },
    {
        tag:'interiors',
        name:'interiors-18',
        fileExtension:'jpeg',
    },
]

export default interiorsImagesArray;