import React from "react";
import { withTranslation } from "react-i18next";
import GalleryIntroduction from "../components/Gallery/GalleryIntroduction";
import GalleryLayout from "../components/Gallery/GalleryLayout";

const GalleryScreen = ({ t }) => {
    return (
        <div uk-filter="target: .js-filter" data-uk-height-viewport="offset-top:true;">
            <GalleryIntroduction/>
            <GalleryLayout/>
        </div>
    );
};

export default withTranslation()(GalleryScreen);