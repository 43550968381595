import React from "react";
import { withTranslation } from "react-i18next";
import ctm from "../../assets/OtherProjects/ctm.jpeg";
import brookyln from "../../assets/OtherProjects/brooklyn.jpg";
import business from "../../assets/OtherProjects/business.jpg";
import thumbnailBusiness from "../../assets/OtherProjects/thumbnail/business.jpg";
import thumbnailCtm from "../../assets/OtherProjects/thumbnail/ctm.jpg";
import thumbnailBrookyln from "../../assets/OtherProjects/thumbnail/brooklyn.jpg";
import LazyLoad from "react-lazy-load";

const OtherProjectsDonedLayout = ({ t }) => {
    return (
        <section className="uk-section">
            <div className="uk-container uk-container-large">
                <div className="uk-child-width-1-2 uk-child-width-1-4@m" data-uk-grid data-uk-lightbox data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div>
                        <div className="uk-text-center">
                            <a href={brookyln} className="uk-link-reset" data-caption="Brooklyn Park">
                                <div className="uk-inline-clip uk-transition-toggle uk-width-1-1" tabIndex="0">
                                    <LazyLoad>
                                        <img src={thumbnailBrookyln} alt="" loading="lazy" className="uk-border-rounded uk-width-1-1" height={340} width={320}/>
                                    </LazyLoad>
                                    <div className="uk-position-center">
                                        <div className="uk-transition-slide-top-small"><div className="uk-h3 uk-margin-remove uk-text-bold text-white">Brooklyn</div></div>
                                        <div className="uk-transition-slide-bottom-small"><div className="uk-h3 uk-margin-remove text-white uk-text-bold">Park</div></div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className="uk-text-center">
                            <a href={ctm} className="uk-link-reset" data-caption="Çerkezköy Ticaret Merkezi">
                                <div className="uk-inline-clip uk-transition-toggle uk-width-1-1" tabIndex="0">
                                    <LazyLoad>
                                        <img src={thumbnailCtm} alt="" loading="lazy" className="uk-border-rounded uk-width-1-1" height={340} width={320}/>
                                    </LazyLoad>
                                    <div className="uk-position-center">
                                        <div className="uk-transition-slide-top-small"><div className="uk-h3 uk-margin-remove uk-text-bold text-white">Çerkezköy</div></div>
                                        <div className="uk-transition-slide-bottom-small"><div className="uk-h3 uk-margin-remove text-white uk-text-bold">Ticaret Merkezi</div></div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className="uk-text-center">
                            <a href={business} className="uk-link-reset" data-caption="Business İstanbul">
                                <div className="uk-inline-clip uk-transition-toggle uk-width-1-1" tabIndex="0">
                                    <LazyLoad>
                                        <img src={thumbnailBusiness} alt="" loading="lazy" className="uk-border-rounded uk-width-1-1" height={340} width={320}/>
                                    </LazyLoad>
                                    <div className="uk-position-center">
                                        <div className="uk-transition-slide-top-small"><div className="uk-h3 uk-margin-remove uk-text-bold text-white">Business</div></div>
                                        <div className="uk-transition-slide-bottom-small"><div className="uk-h3 uk-margin-remove text-white uk-text-bold">İstanbul</div></div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(OtherProjectsDonedLayout);