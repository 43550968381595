/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";

const Content = ({ t }) => {
    return (
        <div className="uk-padding">
            <div className="uk-h1 uk-text-bold uk-margin-bottom">Akıllı <span className="emphasis-color">Ev Sistemleri</span></div>
            <p className="uk-text-muted">Evinizin kontrolünün her zaman sizde olduğu full fonksiyon akıllı ev sistemi. Yeni akıllı ev sistemi evinize birçok konuda kolaylık sağlar.</p>
            <ul className="uk-list uk-list-bullet">
                <li>Otomatik Aydınlatma</li>
                <li>Güvenlik Kameraları</li>
                <li>Akıllı Sulama Sistemi</li>
                <li>İnterkom</li>
                <li>Priz Yönetimi</li>
                <li>Teknolojik Sensörler</li>
                <li>Gelişmiş İklimlendirme Kontrolü</li>
                <li>Sesli Komut Sistemi</li>
            </ul>
        </div>
    );
};

export default withTranslation()(Content);