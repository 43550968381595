const outDoorsImagesArray = [
    {
        tag:'outdoors',
        name:'outdoors-1',
        fileExtension:'jpeg',
    },
    {
        tag:'outdoors',
        name:'outdoors-2',
        fileExtension:'jpeg',
    },
    {
        tag:'outdoors',
        name:'outdoors-3',
        fileExtension:'jpeg',
    },
    {
        tag:'outdoors',
        name:'outdoors-4',
        fileExtension:'jpeg',
    },
    {
        tag:'outdoors',
        name:'outdoors-5',
        fileExtension:'jpeg',
    },
    {
        tag:'outdoors',
        name:'outdoors-6',
        fileExtension:'jpeg',
    },
    {
        tag:'outdoors',
        name:'outdoors-7',
        fileExtension:'jpeg',
    },
    {
        tag:'outdoors',
        name:'outdoors-8',
        fileExtension:'jpeg',
    },
    {
        tag:'outdoors',
        name:'outdoors-9',
        fileExtension:'jpeg',
    },
    {
        tag:'outdoors',
        name:'outdoors-10',
        fileExtension:'jpeg',
    },
    {
        tag:'outdoors',
        name:'outdoors-11',
        fileExtension:'jpeg',
    },
    {
        tag:'outdoors',
        name:'outdoors-12',
        fileExtension:'jpeg',
    },
    {
        tag:'outdoors',
        name:'outdoors-13',
        fileExtension:'jpeg',
    },
    {
        tag:'outdoors',
        name:'outdoors-14',
        fileExtension:'jpg',
    },
    {
        tag:'outdoors',
        name:'outdoors-14',
        fileExtension:'jpg',
    },
    {
        tag:'outdoors',
        name:'outdoors-15',
        fileExtension:'jpg',
    },
    {
        tag:'outdoors',
        name:'outdoors-16',
        fileExtension:'jpeg',
    },
    {
        tag:'outdoors',
        name:'outdoors-17',
        fileExtension:'jpeg',
    },
    {
        tag:'outdoors',
        name:'outdoors-18',
        fileExtension:'jpeg',
    },
]

export default outDoorsImagesArray;