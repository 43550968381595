/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";

const ProjectDetailSiteFeatures = ({ t }) => {
    return (
        <>
            <section className="uk-section uk-padding-remove-bottom">
                <div className="uk-container">
                    <div className="uk-flex uk-flex-center uk-flex-middle uk-text-center" data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                        <div>
                            <h1 className="uk-text-bold">Proje <span className="emphasis-color">Özellikleri</span></h1>
                            <p>Tüm villalarda aşağıdaki özelliklerde akıllı ev sistemi uygulanacaktır;</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withTranslation()(ProjectDetailSiteFeatures);