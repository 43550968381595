import React from "react";
import { withTranslation } from "react-i18next";
import phonyloopsLogo from "../assets/phonyloops-logo.png"

const Footer = ({ t }) => {
    return (
        <footer className="uk-section uk-section-small bg-white">
            <div className="uk-container">
                <div className="uk-flex uk-flex-between uk-flex-middle uk-visible@m" data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div>
                        <div>Copyright 2022 © Bütün hakları saklıdır.</div>
                    </div>
                    <div>
                        <a href="https://www.phonyloops.com" target="_blank"><img src={phonyloopsLogo} alt="" loading="lazy"></img></a>
                    </div>
                </div>
                <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-text-center uk-hidden@m" data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div>
                        <div className="uk-margin-bottom">Copyright 2022 © Bütün hakları saklıdır.</div>
                    </div>
                    <div>
                        <a href="https://www.phonyloops.com" target="_blank"><img src={phonyloopsLogo} alt="" loading="lazy"></img></a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default withTranslation()(Footer);