/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import ProjectDetailCards from "../components/ProjectDetail/ProjectDetailCards";
import ProjectDetailGallery from "../components/ProjectDetail/ProjectDetailGallery";
import ProjectDetailSiteFeatures from "../components/ProjectDetail/ProjectDetailSiteFeatures";

const ProjectDetailScreen = ({ t }) => {
    return (
        <>
            <ProjectDetailGallery/>
            <ProjectDetailSiteFeatures/>
            <ProjectDetailCards/>
        </>
    );
};

export default withTranslation()(ProjectDetailScreen);