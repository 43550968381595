/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { withTranslation } from "react-i18next";

const ContactMap = ({ t }) => {
    const iframeSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3015.305748952841!2d29.376110915644976!3d40.90904153347393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cad831a7f89373%3A0x63ff5d0a7389d2af!2zVGVwZcO2cmVuLCBEZXJ2acWfcGHFn2EgQ2QuLCAzNDk1OSBUdXpsYS_EsHN0YW5idWw!5e0!3m2!1str!2str!4v1658443885025!5m2!1str!2str";
    return (
        <section>
            <div>
                <div className="uk-margin-auto uk-grid-collapse uk-child-width-1-1" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="grid-border uk-flex uk-flex-column uk-flex-center">
                        <div className="uk-width-1-1 uk-height-large">
                            <iframe
                            src={iframeSrc}
                            frameBorder="0"
                            style={{ border: 0 }}
                            className="uk-width-1-1 uk-height-1-1"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(ContactMap);