/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Content from "./Content";
import image1 from "../../assets/Smart Home System/1.jpg";
import image2 from "../../assets/Smart Home System/2.png";
import image3 from "../../assets/Smart Home System/3.jpg";
import image4 from "../../assets/Smart Home System/4.jpg";
import LazyLoad from "react-lazy-load";

const Highlight = ({ t }) => {
    return (
        <section className="uk-section uk-section-small project-sub-detail-section" data-uk-height-viewport="offset-top:true;">
            <div className="uk-container uk-container-xlarge uk-height-1-1">
                <div className="link uk-margin-medium-bottom">
                    <Link to="/project-detail" className="uk-link-reset">Geri Dön</Link>
                </div>
                <div className="uk-child-width-1-2@m uk-flex-top" data-uk-grid>
                    <div>
                        <div data-uk-slideshow="animation: push;">
                            <div className="uk-position-relative uk-visible-toggle" tabindex="-1">
                                <ul className="uk-slideshow-items">
                                    <li>
                                        <LazyLoad className="uk-height-1-1 uk-width-1-1">
                                            <img src={image1} alt="slider-1" className="uk-width-1-1 uk-height-1-1" uk-cover />
                                        </LazyLoad>
                                    </li>
                                    <li>
                                        <LazyLoad className="uk-height-1-1 uk-width-1-1">
                                            <img src={image2} alt="slider-2" className="uk-width-1-1 uk-height-1-1" uk-cover />
                                        </LazyLoad>
                                    </li>
                                    <li>
                                        <LazyLoad className="uk-height-1-1 uk-width-1-1">
                                            <img src={image3} alt="slider-3" className="uk-width-1-1 uk-height-1-1" uk-cover />
                                        </LazyLoad>
                                    </li>
                                    <li>
                                        <LazyLoad className="uk-height-1-1 uk-width-1-1">
                                            <img src={image4} alt="slider-4" className="uk-width-1-1 uk-height-1-1" uk-cover />
                                        </LazyLoad>
                                    </li>
                                </ul>
                                <a className="uk-position-center-left uk-position-small" href="#" data-uk-slidenav-previous data-uk-slideshow-item="previous"></a>
                                <a className="uk-position-center-right uk-position-small" href="#" data-uk-slidenav-next data-uk-slideshow-item="next"></a>
                            </div>
                            {/* <ul className="uk-slideshow-nav uk-dotnav uk-position-bottom-center uk-margin"></ul> */}
                        </div>
                    </div>
                    <div>
                        <Content />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(Highlight);