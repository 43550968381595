import React from "react";
import { withTranslation } from "react-i18next";
import OtherProjectsContinuedIntro from "../components/OtherProjects/OtherProjectsContinuedIntro";
import OtherProjectsContinuedLayout from "../components/OtherProjects/OtherProjectsContinuedLayout";
import OtherProjectsDonedIntro from "../components/OtherProjects/OtherProjectsDonedIntro";
import OtherProjectsDonedLayout from "../components/OtherProjects/OtherProjectsDonedLayout";

const OtherProjectsScreen = ({ t }) => {
    return (
        <>
            <div data-uk-height-viewport="offset-top:true;offset-bottom:148px;">
                <OtherProjectsContinuedIntro/>
                <OtherProjectsContinuedLayout/>
                <OtherProjectsDonedIntro/>
                <OtherProjectsDonedLayout/>
            </div>
        </>
    );
};

export default withTranslation()(OtherProjectsScreen);