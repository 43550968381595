import React from 'react';
import ProjectPlansIntro from "../components/ProjectPlans/ProjectPlansIntro";
import ProjectPlansIntroTwo from "../components/ProjectPlans/ProjectPlansIntroTwo";
import ProjectPlansLayout from "../components/ProjectPlans/ProjectPlansLayout";
import ProjectPlansLayoutTwo from "../components/ProjectPlans/ProjectPlansLayoutTwo";
import ProjectPlansIntroThree from "../components/ProjectPlans/ProjectPlansIntroThree";
import ProjectPlansLayoutThree from "../components/ProjectPlans/ProjectPlansLayoutThree";
import ProjectPlansSiteFeatures from '../components/ProjectPlans/ProjectPlansSiteFeatures';
import ProjectPlansGallery from '../components/ProjectPlans/ProjectPlansGallery';

export default function ProjectPlansScreen() {
  return (
    <>
        <ProjectPlansGallery />
        <ProjectPlansSiteFeatures/>
        <ProjectPlansIntro/>
        <ProjectPlansLayout/>
        <ProjectPlansIntroTwo/>
        <ProjectPlansLayoutTwo/>
        <ProjectPlansIntroThree/>
        <ProjectPlansLayoutThree/> 
    </>
  )
}
