/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import LazyLoad from "react-lazy-load";
import projectsPlanImagesArray from "../../data/projectsPlanImagesData";

const ProjectPlansGallery = ({ t }) => {
    return (
        <section className="project-plans-section">
            <div>
                <div className="uk-position-relative uk-visible-toggle" tabIndex="-1" data-uk-slider="autoplay:true; autoplay-interval:4000; sets:true;">
                    <ul className="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-small" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;" data-uk-lightbox>
                        {projectsPlanImagesArray.map((item) => (
                            <li key={item._id}>
                                <div className="uk-panel uk-width-1-1 uk-height-1-1">
                                    <a href={process.env.PUBLIC_URL + `/assets/Gallery/${item.tag}/${item.name}.${item.fileExtension}`} className="uk-width-1-1 uk-height-1-1">
                                        <div className="image-box">
                                            <LazyLoad className="uk-width-1-1 uk-height-1-1">
                                                <img src={process.env.PUBLIC_URL + `/assets/Gallery/${item.tag}/${item.name}.${item.fileExtension}`} alt={item.name} loading="lazy" data-uk-img className="uk-width-1-1 uk-height-1-1 uk-object-cover" height={310} width={480}/>
                                            </LazyLoad>
                                        </div>
                                    </a>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(ProjectPlansGallery);