const navbarItems = [
    {
        title: 'Anasayfa',
        link: '/',
    },
    {
        title: 'Proje Özellikleri',
        link: '/project-detail',
    },
    {
        title: 'Proje Planları',
        link: '/project-plans',
    },
    {
        title: 'Diğer Projelerimiz',
        link: '/other-projects',
    },
    {
        title: 'Galeri',
        link: '/gallery',
    },
    {
        title: 'İletişim',
        link: '/contact',
    },
]

export default navbarItems