/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";

const Content = ({ t }) => {
    return (
        <div className="uk-padding">
            <div className="uk-h1 uk-text-bold uk-margin-bottom">Kapalı <span className="emphasis-color">Otopark</span></div>
            <p className="uk-text-muted">Her villaya özel 5 araçlık kapalı otopark imkânı ve 2 adet elektrikli araç şaz ünitesi alt yapısı ile size ayrıcalıklı olmanın keyfini sunuyoruz.</p>
        </div>
    );
};

export default withTranslation()(Content);