import React from "react";
import { withTranslation } from "react-i18next";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";
import image from "../../assets/Home/home-3.jpeg";

const HomeThirdSplitColumn = ({ t }) => {
    return (
        <section className="uk-section uk-padding-remove-top">
            <div className="uk-container uk-container-xlarge">
                <div className="uk-child-width-1-2@m uk-flex-middle uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div>
                        <div className="background-position uk-width-1-1 home-3 uk-visible@m"></div>
                        <LazyLoad>
                            <img src={image} alt="home-3" loading="lazy" className="uk-width-1-1 uk-hidden@m"  height={600} width={700}/>
                        </LazyLoad>
                    </div>
                    <div>
                        <div className="uk-padding">
                            <div className="uk-h3 uk-text-bold uk-margin-remove">Bölgenin en ihtiraslı mimarisine sahip evinizde, yeşilin huzurlu ruhu size eşlik etsin</div>
                            <div className="uk-h4">
                                4000 m² lik arazi üzerine inşa edilen botanika vilları 5 araçlık  kapalı otoparkı, elektrik araç şarj alt yapısı, full akıllı ev sistemleri, modern mimarisi müstakil yaşam imkanını sunması  ve geniş yaşam alanları ile bir çok avantajlara sahiptir.
                            </div>
                            <Link to="/gallery" className="uk-button uk-button-primary">Detay</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(HomeThirdSplitColumn);