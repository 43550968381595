import React from "react";
import { withTranslation } from "react-i18next";

const OtherProjectsDonedIntro = ({ t }) => {
    return (
        <section className="uk-section uk-padding-remove-bottom">
            <div className="uk-container uk-container-large">
                <h1 className="uk-h2 uk-text-bold" data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <span className="emphasis-color">Biten </span><span>Projeler</span>
                </h1>
            </div>
        </section>
    );
};

export default withTranslation()(OtherProjectsDonedIntro);