const projectsPlanImagesArray = [
    {
        tag:'projects-plan',
        name:'projects-plan-11',
        fileExtension:'jpeg',
    },
    {
        tag:'projects-plan',
        name:'projects-plan-12',
        fileExtension:'jpeg',
    },
    {
        tag:'projects-plan',
        name:'projects-plan-13',
        fileExtension:'jpeg',
    },
    {
        tag:'projects-plan',
        name:'projects-plan-14',
        fileExtension:'jpeg',
    },
    {
        tag:'projects-plan',
        name:'projects-plan-15',
        fileExtension:'jpeg',
    },
    {
        tag:'projects-plan',
        name:'projects-plan-1',
        fileExtension:'jpeg',
    },
    {
        tag:'projects-plan',
        name:'projects-plan-2',
        fileExtension:'jpeg',
    },
    {
        tag:'projects-plan',
        name:'projects-plan-3',
        fileExtension:'jpeg',
    },
    {
        tag:'projects-plan',
        name:'projects-plan-4',
        fileExtension:'jpeg',
    },
    {
        tag:'projects-plan',
        name:'projects-plan-5',
        fileExtension:'jpeg',
    },
    {
        tag:'projects-plan',
        name:'projects-plan-6',
        fileExtension:'jpeg',
    },
    {
        tag:'projects-plan',
        name:'projects-plan-7',
        fileExtension:'jpeg',
    },
    {
        tag:'projects-plan',
        name:'projects-plan-8',
        fileExtension:'jpeg',
    },
    {
        tag:'projects-plan',
        name:'projects-plan-9',
        fileExtension:'jpeg',
    },
    {
        tag:'projects-plan',
        name:'projects-plan-10',
        fileExtension:'jpeg',
    },
]

export default projectsPlanImagesArray;