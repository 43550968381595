/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";

const Content = ({ t }) => {
    return (
        <div className="uk-padding">
            <div className="uk-h1 uk-text-bold uk-margin-bottom">Yerden <span className="emphasis-color">Isıtma Sistemi</span></div>
            <p className="uk-text-muted">Yerden ısıtma sayesinde evinizin her yeri aynı anda eşit derecede ısıtılarak daha konforlu bir alan sağlamış oluyoruz. Villadaki 4 banyo da aynı anda sıcak su erişimi verilerek anlık sıcak su ihtiyacınızı karşılayabilecek bir sistem sunuyoruz.</p>
        </div>
    );
};

export default withTranslation()(Content);