/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { React} from "react";

const Navbar = ({ t , toggle }) => {
    return (
        <header className="navbar-bg" data-uk-sticky>
            <nav className="uk-container uk-container-xlarge uk-navbar-sticky uk-position-relative" data-uk-navbar data-uk-scrollspy="cls: uk-animation-fade; delay:300;">
                <div className="uk-navbar-left">
                    <div className="uk-navbar-item logo uk-padding-remove uk-text-bold emphasis-color">
                        <Link to="/" className="uk-link-reset uk-flex uk-flex-middle"><div className="uk-margin-small-right" uk-icon="icon:icn-home"></div> Botanika Villaları</Link>
                    </div>
                </div>
                <div className="uk-navbar-right uk-visible@l">
                    <div className="uk-navbar-item link">
                        <Link to="/" className="uk-link-reset">Anasayfa</Link>
                    </div>
                    <div className="uk-navbar-item link">
                        <Link to="/project-detail" className="uk-link-reset">Proje Özellikleri</Link>
                    </div>
                    <div className="uk-navbar-item link">
                        <Link to="/project-plans" className="uk-link-reset">Proje Planları</Link>
                    </div>
                    <div className="uk-navbar-item link">
                        <Link to="/other-projects" className="uk-link-reset">Diğer Projeler</Link>
                    </div>
                    <div className="uk-navbar-item link">
                        <Link to="/gallery" className="uk-link-reset">Galeri</Link>
                    </div>
                    <div className="uk-navbar-item link">
                        <Link to="/contact" className="uk-link-reset">İletişim</Link>
                    </div>
                </div>
                <div className="uk-navbar-right uk-hidden@l">
                    <div className="uk-navbar-item emphasis-color">
                        <a className="uk-link-reset uk-text-large" data-uk-icon="icon:menu; ratio:1.75;" onClick={toggle}></a>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default withTranslation()(Navbar);