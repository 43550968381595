/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { withTranslation } from "react-i18next";
//import { Link } from "react-router-dom";
import image from "../../assets/Home/home-promo.jpg";
import catalog from "../../assets/katalog.pdf";

const HomeBanner = ({ t }) => {
    return (
        <section className="uk-container uk-container-xlarge home-promo-section">
            <div className="background-position uk-background-center-center uk-width-1-1 home-promo uk-visible@m" data-uk-height-viewport="offset-top:true;offset-bottom:20px;" height={1400} width={800}>
                <div className="uk-width-2-3@m uk-flex uk-flex-middle" data-uk-height-viewport="offset-top:true;offset-bottom:20px;" data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="uk-margin-medium-left">
                        <div className="uk-h1 text-white fw-600 lh-15 ff-playfair">
                            <span style={{fontFamily: 'Comic Sans'}}>13</span> Eşsiz Villa, Doğa ile <br/>Bütünleşen Yaşamın Anahtarını Sunuyor
                        </div>
                        <a target="_blank" href={catalog} className="uk-button uk-button-default ff-playfair">Bilgi Al</a>
                        <div>
                            <ul className="uk-iconnav uk-margin-medium-top">
                                <li>
                                    <a href="https://www.facebook.com/Botanikavillalarii" target="_blank">
                                        <div className="circle">
                                            <div  uk-icon="icon: facebook"></div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/botanikavillalarii/?igshid=ZDdkNTZiNTM%3D" target="_blank">
                                        <div className="circle">
                                            <div  uk-icon="icon: instagram"></div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:botanikavillalari@gmail.com">
                                        <div className="circle">
                                            <div  uk-icon="icon: mail"></div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="tel:+905510730070">
                                        <div className="circle">
                                            <div  uk-icon="icon: whatsapp"></div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="uk-inline uk-width-1-1 home-promo-section uk-hidden@m">
                <img src={image} alt="promo" loading="lazy" className="uk-width-1-1" data-uk-height-viewport="offset-top:true;" height={1400} width={800}/>
                <div className="uk-position-center">
                    <div className="uk-width-2-3@m uk-flex uk-flex-middle" data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                        <div className="uk-margin-left uk-margin-right">
                            <div className="uk-h2 text-white fw-600 lh-15 ff-playfair">
                                <span style={{fontFamily: 'Comic Sans'}}>13</span> Eşsiz Villa, Doğa <br/>ile Bütünleşen Yaşamın Anahtarını Sunuyor
                            </div>
                            <a target="_blank" href={catalog} className="uk-button uk-button-default ff-playfair">Bilgi Al</a>
                            <div>
                                <ul className="uk-iconnav uk-margin-medium-top">
                                    <li>
                                        <a href="https://www.facebook.com/Botanikavillalarii" target="_blank">
                                            <div className="circle">
                                                <div uk-icon="icon: facebook"></div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/botanikavillalarii/?igshid=ZDdkNTZiNTM%3D" target="_blank">
                                            <div className="circle">
                                                <div uk-icon="icon: instagram"></div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:botanikavillalari@gmail.com">
                                            <div className="circle">
                                                <div uk-icon="icon: mail"></div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:+905510730070">
                                            <div className="circle">
                                                <div uk-icon="icon: whatsapp"></div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(HomeBanner);