/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import LazyLoad from "react-lazy-load";
import image1 from "../../assets/ProjectDetail/zemin.png";

const ProjectPlansLayout = ({ t }) => {
    return (
        <>
            <section className="uk-section uk-padding-remove-bottom">
                <div className="uk-container uk-container-large">
                    <div className="" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                        <div className="uk-width-2-3@m">
                            <div className="uk-child-width-1-2@m uk-grid-small uk-text-center" data-uk-grid>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Antre :</span> 5.50 m²
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Merdiven Evi :</span> 14.15 m²
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Hol :</span> 1.80 m²
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Kiler :</span> 2.40 m²
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Mutfak :</span> 20.30 m²
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Banyo :</span> 6.30 m²
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Yatak Odası :</span> 15.60 m²
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Salon :</span> 44.35 m²
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-3@m uk-flex uk-flex-center" data-uk-lightbox>
                            <a className="uk-inline-clip uk-transition-toggle" tabIndex="0" href={image1}>
                                <LazyLoad>
                                    <img src={image1} alt="" data-uk-img loading="lazy" height={620} width={440}/>
                                </LazyLoad>
                                <div className="uk-transition-fade uk-position-cover uk-overlay uk-overlay-default uk-flex uk-flex-center uk-flex-middle">
                                    <div className="emphasis-color" data-uk-icon="icon:expand; ratio:3;"/>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withTranslation()(ProjectPlansLayout);