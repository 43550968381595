const projectDetailArray = [
    {
        id:'1',
        title:'Akıllı Ev Sistemi',
        desc:'Evinizin kontrolünün her zaman sizde olduğu full fonksiyon akıllı ev sistemi.',
        image:'smart-home-system.jpg',
        alt:'Smart Home System',
        url:'/smart-home-system',
    },
    {
        id:'2',
        title:'Yerden Isıtma Sistemi',
        desc:'Yerden ısıtma sayesinde evinizin  her yeri aynı anda eşit derecede ısıtılarak daha konforlu bir alan sağlamış oluyoruz. Villadaki 4 banyo da  aynı anda sıcak su erişimi verilerek anlık sıcak su ihtiyacınızı karşılayabilecek bir sistem sunuyoruz.',
        image:'underfloor-heating-system.jpg',
        alt:'Underfloor Heating System',
        url:'/underfloor-heating-system',
    },
    {
        id:'3',
        title:'Özel Yüzme Havuzu',
        desc:'Taşmalı sistem ile  kendini temizleme özelliğine sahip havuzumuzda  konforun ve lüksün  keyfini çıkarabileceksiniz.',
        image:'private-swimming-pool.jpg',
        alt:'Private Swimming Pool',
        url:'/private-swimming-pool',
    },
    {
        id:'4',
        title:'Kapalı Otopark',
        desc:'Her villaya özel 5 araçlık kapalı otopark imkanı ve 2 adet elektrikli araç şaz ünitesi alt yapısı ile   size  ayrıcalıklı olmanın keyfini sunuyoruz.',
        image:'parking-garage.jpg',
        alt:'Parking Garage',
        url:'/parking-garage',
    },
    {
        id:'5',
        title:'Sosyal Aktivite Alanları',
        desc:'Hayallerinize göre kendi tasarımını yapabileceğiniz aktivite alanları sunuyoruz. Sinema odası ,fıtness , sauna ,hamam …  Hayal etmek sizden inşa etmek bizden …',
        image:'social-activity-areas.jpg',
        alt:'Social Activity Areas',
        url:'/social-activity-areas',
    },
]

export default projectDetailArray;