import React from "react";
import { withTranslation } from "react-i18next";
import ContactForm from "../components/Contact/ContactForm";
import ContactMap from "../components/Contact/ContactMap";

const ContactScreen = ({ t }) => {
    return (
        <>
            <ContactForm/>
            <ContactMap/>
        </>
    );
};

export default withTranslation()(ContactScreen);